import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableColumns } from '../../../types';
import { Sendout, Template, Card } from '../../../types';
import { DateFnsModule } from 'ngx-date-fns';
import { TemplateStatusPipe } from '../../../pipes/TemplateStatus.pipe';
import { SendoutStatusPipe } from '../../../pipes/SendoutStatus.pipe';

@Component({
  selector: 'app-dynamic-table',
  standalone: true,
  imports: [CommonModule, DateFnsModule, TemplateStatusPipe, SendoutStatusPipe],
  templateUrl: './dynamic-table.component.html',
  styleUrl: './dynamic-table.component.css',
})
export class DynamicTableComponent implements OnInit {
  @Input() columns: TableColumns[] = [];
  @Input() data: (any | Sendout | Template | Card)[] = [];
  @Input() emptyStateText: string = '';
  @Output() clickEvent = new EventEmitter();
  sortColumn: string = '';
  isDesc: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  sortData(column: TableColumns) {
    const { field } = column;

    if (this.sortColumn === field) {
      this.isDesc = !this.isDesc;
    } else {
      this.sortColumn = field;
      this.isDesc = true;
    }

    this.data.sort((a, b) => {
      const valueA = a[field];
      const valueB = b[field];

      let comparison =
        typeof column.sort == 'boolean'
          ? this.compareValues(valueA, valueB)
          : column.sort(valueA, valueB);

      return this.isDesc ? -comparison : comparison;
    });
  }

  compareValues(valueA: unknown, valueB: unknown): number {
    if (valueA === null || valueA === undefined) return -1;
    if (valueB === null || valueB === undefined) return 1;

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return valueA.localeCompare(valueB);
    }

    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return valueA - valueB;
    }

    if (valueA instanceof Date && valueB instanceof Date) {
      return valueA.getTime() - valueB.getTime();
    }

    return 0;
  }

  handleRowClick(row: any | Sendout | Template | Card) {
    this.clickEvent.emit(row);
  }
}
