import { Pipe, PipeTransform } from '@angular/core';
import { TemplateStatus, TemplateStatusEnum } from '../types';

@Pipe({
  name: 'templateStatus',
  standalone: true,
})
export class TemplateStatusPipe implements PipeTransform {
  transform(
    value: TemplateStatusEnum,
    mode: 'label' | 'color' = 'label'
  ): string {
    if (mode === 'label') {
      return this.getLabel(value);
    }
    return String(value);
  }
  private getLabel(value: TemplateStatusEnum): string {
    switch (value) {
      case TemplateStatus.Draft:
        return 'Kladde';
        break;
      case TemplateStatus.PendingAgillicImplementation:
        return 'Pending Agillic implementation';
        break;
      case TemplateStatus.Published:
        return 'Publiceret';
        break;
      case TemplateStatus.Unpublished:
        return 'Upubliceret';
        break;
      case TemplateStatus.Unknown:
        return 'Ukendt';
        break;
      default:
        return value;
        break;
    }
  }
}
