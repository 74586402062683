export const UserType = {
  ActiveDirectory: 'ActiveDirectory',
  AgillicAdvisor: 'AgillicAdvisor',
  Unknown: 'Unknown',
} as const;
export type UserTypeEnum = (typeof UserType)[keyof typeof UserType];

export const SendoutStatus = {
  Draft: 'Draft',
  AwaitingApproval: 'AwaitingApproval',
  Approved: 'Approved',
  Rejected: 'Rejected',
  Executing: 'Executing',
  Completed: 'Completed',
  Expired: 'Expired',
  Unknown: 'Unknown',
} as const;
export type SendoutStatusEnum =
  (typeof SendoutStatus)[keyof typeof SendoutStatus];

export const TemplateStatus = {
  Draft: 'Draft',
  PendingAgillicImplementation: 'PendingAgillicImplementation',
  Published: 'Published',
  Unpublished: 'Unpublished',
  Unknown: 'Unknown',
} as const;
export type TemplateStatusEnum =
  (typeof TemplateStatus)[keyof typeof TemplateStatus];

export const EventType = {
  Event: 'Event',
  PersonalAppointment: 'PersonalAppointment',
} as const;
export type EventTypeEnum = (typeof EventType)[keyof typeof EventType];

export const TemplateCategory = {
  Brand: 'Brand',
  Consultation: 'Consultation',
  Activities: 'Activities',
  Seasonal: 'Seasonal',
  Other: 'Other',
} as const;
export type TemplateCategoryEnum =
  (typeof TemplateCategory)[keyof typeof TemplateCategory];

export interface Options {
  value: string;
  viewValue: string;
}

export interface Option {
  value: number;
  viewValue: string;
}

export interface CreateSendout {
  sendoutTemplateId: number;
  storeId: number;
  updatedBy: number;
  reviewedBy: number;
  sendoutName: string;
  status: string;
  eventId: string | null;
  contentSubjectLine: string;
  contentPreheader: string;
  contentHeadline: string;
  contentBodyCopy: string;
  contentCtaText: string;
  contentCtaUrl: string;
  contentImageUrl: string;
  contentDisclaimer: string;
  sendoutDate: string;
  createdBy: number;
  contactPerson: string | null;
}

export interface Filter {
  id: number;
  text: string;
  classes: string;
  checked: boolean;
}

export interface SendoutColumn {
  id: number;
  name: string;
  icon?: string;
}

export interface Event {
  id: string;
  type: EventTypeEnum;
  status: string;
  storeId: string;
  startsAt: Date;
  endsAt: Date;
  name: string;
  imageUrl: string;
  isDeleted: boolean;
  link: string | null;
  consultationId: string | null;
  bookingCategoryId: number | null;
  bookingCategoryLabel: string | null;
  externalPartnerBrandId: number | null;
  externalPartnerBrand: Brand | null;
}

export interface EventWithSendouts extends Event {
  sendouts: Sendout[];
}

export interface GenericCard {
  overline?: string;
  title?: string;
  description?: string;
  items?: { term: string; value: string }[];
  footer?: string;
  imageUrl: string;
}

export interface Card {
  id: number | string;
  cardHeading: string;
  before?: number;
  after?: number;
  type?: string;
  status?: string;
  storeId?: string;
  store?: Store;
  startsAt?: string;
  endsAt?: string;
  name?: string;
  imageUrl?: string;
  sendoutTemplateId?: number;
  createdAt?: string;
  createdBy?: number;
  updatedAt?: string;
  updatedBy?: number;
  reviewedAt?: string;
  reviewedBy?: string;
  sendoutName?: string;
  eventId?: string;
  contentSubjectLine?: string;
  contentPreheader?: string;
  contentHeadline?: string;
  contentBodyCopy?: string;
  contentCtaText?: string;
  contentCtaUrl?: string;
  isDeleted?: boolean;
  contentImageUrl?: 'string';
  contentDisclaimer?: 'string';
  sendoutDate?: '2024-07-03';
  templateName?: string;
  description?: string;
  targetGroupDescription?: string;
  brandId?: string;
  storeAvailability?: string;
  eventRequired?: boolean;
  defaultContentSubjectLine?: string;
  defaultContentPreheader?: string;
  defaultContentHeadline?: string;
  defaultContentBodyCopy?: string;
  defaultContentCtaText?: string;
  defaultContentCtaUrl?: string;
  allowOverrideSubjectLine?: boolean;
  allowOverridePreheader?: boolean;
  allowOverrideHeadline?: boolean;
  allowOverrideBodyCopy?: boolean;
  allowOverrideCtaText?: boolean;
  allowOverrideCtaUrl?: boolean;

  category?: string;
  defaultContentDisclaimer?: string;
  availableImages?: string;
  allowOverrideDisclaimer?: boolean;
  createdByName?: null | string;
  updatedByName?: null | string;
  brandName?: string;

  contactPerson?: string | null;

  link?: string;
  consultationId?: string;
}

export interface GroupedDataEntity<T> {
  entity: T;
}
export interface GroupedDataSet<T> {
  groups: GroupedDataGroup<T>[];
}
export interface GroupedDataGroup<T> {
  id: string;
  label: string;
  entities: T[];
}

export interface Sendout {
  id: number;
  sendoutTemplateId: number;
  storeId: number;
  store?: Store;

  createdAt: Date;
  createdBy: number;
  updatedByName: string;
  createdByName: string | null;
  updatedAt: Date;
  updatedBy: number;
  reviewedAt: Date | null;
  reviewedBy: number;
  sendoutName: string;
  status: SendoutStatusEnum;

  eventId: string | null;
  event?: Event;

  contentSubjectLine: string;
  contentPreheader: string;
  contentHeadline: string;
  contentBodyCopy: string;
  contentCtaText: string;
  contentCtaUrl: string;
  isDeleted: boolean;
  contentImageUrl: string;
  contentDisclaimer: string;
  contactPerson: string | null;
  sendoutDate: Date;
}

export interface UpdateSendout {
  sendoutTemplateId: number;
  storeId: number;
  updatedBy: number;
  // updatedAt: Date;
  reviewedBy: number;
  reviewedAt: string | null;
  sendoutName: string;
  status: SendoutStatusEnum;
  eventId: string | null;
  contentSubjectLine: string;
  contentPreheader: string;
  contentHeadline: string;
  contentBodyCopy: string;
  contentCtaText: string;
  contentCtaUrl: string;
  contentImageUrl: string;
  contentDisclaimer: string;
  contactPerson: string | null;
  sendoutDate: string;
  id: number;
}

export interface Week {
  id: number;
  week: string;
  startDate: string;
  endDate: string;
  sendouts: Sendout[];
}

export interface Template {
  id: number;
  createdAt: Date;
  createdBy: number;
  createdByName?: string;
  updatedAt: Date;
  updatedBy: number;
  updatedByName?: string;
  status: string;
  templateName: string;
  templateLabel: string;
  description: string;
  targetGroupDescription: string;
  brandId: number | null;
  brand?: Brand;
  category: TemplateCategoryEnum;
  storeAvailability: string;
  eventRequired: boolean;
  defaultContentSubjectLine: string;
  defaultContentPreheader: string;
  defaultContentHeadline: string;
  defaultContentBodyCopy: string;
  defaultContentCtaText: string;
  defaultContentCtaUrl: string;
  defaultContentDisclaimer: string;
  availableImages: string[];
  allowOverrideSubjectLine: boolean;
  allowOverridePreheader: boolean;
  allowOverrideHeadline: boolean;
  allowOverrideBodyCopy: boolean;
  allowOverrideCtaText: boolean;
  allowOverrideCtaUrl: boolean;
  allowOverrideDisclaimer: boolean;
  isDeleted: boolean;
}

export interface UpdateTemplate {
  id: number;
  updatedBy: number;
  status: string;
  templateName: string;
  templateLabel: string;
  description: string;
  targetGroupDescription: string;
  brandId: number | null;
  category: string;
  storeAvailability: string;
  eventRequired: boolean;
  defaultContentSubjectLine: string;
  defaultContentPreheader: string;
  defaultContentHeadline: string;
  defaultContentBodyCopy: string;
  defaultContentCtaText: string;
  defaultContentCtaUrl: string;
  defaultContentDisclaimer: string;
  availableImages: string;
  allowOverrideSubjectLine: boolean;
  allowOverridePreheader: boolean;
  allowOverrideHeadline: boolean;
  allowOverrideCtaText: boolean;
  allowOverrideCtaUrl: boolean;
  allowOverrideDisclaimer: boolean;
  allowOverrideBodyCopy: boolean;
}

export interface PostTemplate {
  updatedBy: number;
  status: string;
  templateName: string;
  templateLabel: string;
  description: string;
  targetGroupDescription: string;
  brandId: number | null;
  category: string;
  storeAvailability: string;
  eventRequired: boolean;
  defaultContentSubjectLine: string;
  defaultContentPreheader: string;
  defaultContentHeadline: string;
  defaultContentBodyCopy: string;
  defaultContentCtaText: string;
  defaultContentCtaUrl: string;
  defaultContentDisclaimer: string;
  availableImages: string;
  allowOverrideSubjectLine: boolean;
  allowOverridePreheader: boolean;
  allowOverrideHeadline: boolean;
  allowOverrideBodyCopy: boolean;
  allowOverrideCtaText: boolean;
  allowOverrideCtaUrl: boolean;
  allowOverrideDisclaimer: boolean;
  createdBy: number;
}

export interface Brand {
  id: number;
  name: string;
  description: null | string;
  isActive: boolean;
}

export interface Store {
  id: number;
  name: string;
  place: string;
  address: string;
  district: string;
  isActive: boolean;
}

export interface EventBatch {
  id: number;
  batch: EventWithSendouts[];
}

export interface SendoutByWeek {
  week: string;
  sendouts: Sendout[];
}

export interface TableColumns {
  field: string;
  header: string;
  sort: boolean | ((a: any, b: any) => number);
}
export type SendoutViewModel = { [key: string]: unknown } & Sendout & {
    after: number | null;
    before: number | null;
  };
