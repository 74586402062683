import { Pipe, PipeTransform } from '@angular/core';
import { SendoutStatus, SendoutStatusEnum, TemplateStatus } from '../types';

@Pipe({
  name: 'sendoutStatus',
  standalone: true,
})
export class SendoutStatusPipe implements PipeTransform {
  transform(
    value: SendoutStatusEnum,
    mode: 'label' | 'color' | 'class' = 'label'
  ): string {
    if (mode === 'label') {
      return this.getLabel(value);
    } else if (mode === 'color') {
      return this.getColor(value);
    } else if (mode === 'class') {
      return this.getClass(value);
    }
    return String(value);
  }

  private getLabel(value: SendoutStatusEnum): string {
    switch (value) {
      case SendoutStatus.Draft:
        return 'Kladde';
        break;
      case SendoutStatus.AwaitingApproval:
        return 'Afventer godkendelse';
        break;
      case SendoutStatus.Approved:
        return 'Godkendt';
        break;
      case SendoutStatus.Rejected:
        return 'Afvist';
        break;
      case SendoutStatus.Executing:
        return 'Under udsendelse';
        break;
      case SendoutStatus.Completed:
        return 'Afsluttet';
        break;
      default:
        return '';
        break;
    }
  }
  private getColor(value: SendoutStatusEnum): string {
    switch (value) {
      case SendoutStatus.Draft:
        return '#FDDF73';
        break;
      case SendoutStatus.AwaitingApproval:
        return '#FD993D';
        break;
      case SendoutStatus.Approved:
        return '#2CCA1F';
        break;
      case SendoutStatus.Rejected:
        return '#D43A42';
        break;
      case SendoutStatus.Executing:
        return '#AFCBE0';
        break;
      case SendoutStatus.Completed:
        return '#002643';
        break;
      default:
        return 'transparent';
        break;
    }
  }

  private getClass(value: SendoutStatusEnum): string {
    switch (value) {
      case SendoutStatus.Draft:
        return 'variant-circle-kladder';
        break;
      case SendoutStatus.AwaitingApproval:
        return 'variant-circle-afventer';
        break;
      case SendoutStatus.Approved:
        return 'variant-circle-godkendt';
        break;
      case SendoutStatus.Rejected:
        return 'variant-circle-afvist';
        break;
      case SendoutStatus.Executing:
        return 'variant-circle-udsendt';
        break;
      case SendoutStatus.Completed:
        return 'variant-circle-afsluttet';
        break;
      default:
        return '';
        break;
    }
  }
}
